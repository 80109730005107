<template>
  <div class="center">
    <div>
    </div>
  </div>
</template>


<script>
import {session, createsession} from "../js/storage"
import { PublicClientApplication } from '@azure/msal-browser';
import {msalConfig} from "../js/constants"
import router from "../router"

export default {
  name: 'Login',
  data() {
    return {
      MyName: undefined,
      account: undefined,
      accessToken: undefined
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(msalConfig);
    createsession();
    session.set("msalInstance", this.$msalInstance);
  },
  async mounted() {
//    const loginRequest = {scopes: ["User.ReadWrite"]}
    //const loginRequest = {scopes: ['https://storage.azure.com/user_impersonation']};
    const loginRequest = {scopes: ['api://ee3ceec0-1611-4ba4-b408-607defedd27e/access_as_user']};

    await this.$msalInstance.loginPopup(loginRequest)
    .then(function (loginResponse) {
        session.set("name", loginResponse.account.name);
        session.set("accessToken", loginResponse.accessToken);
        session.set("idToken", loginResponse.idToken);
        session.set("loginResponse", loginResponse);
        session.set("authenticated", true);
        console.log(loginResponse);
    }).catch(function (error) {
        console.log(error);
        session.set("authenticated", false);
    });
    if(session.get("authenticated"))
    {
      this.$emitter.emit('loginStatus', 'loggedin');
      router.push("devices");
    }
  }
  
}
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.auth-container {
  display: "block" !important;
}

</style>