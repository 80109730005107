export const msalConfig = {
  auth: {
    clientId: 'ee3ceec0-1611-4ba4-b408-607defedd27e',
    authority:
      'https://login.microsoftonline.com/c0da85ba-664d-4fab-b273-5fef5ea0f645',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
}

export const storageAccountName = 'wmsfirmware';